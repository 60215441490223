
import React, { useEffect, useContext, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { AppSettings } from "./../../config/app-settings.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye} from '@fortawesome/free-solid-svg-icons';
import { POST } from "../../services/api.ts";
import { useMutation } from "@tanstack/react-query";

function ChangePassword() {
	const context = useContext(AppSettings);
	const [redirect, setRedirect] = useState(false);
	const [newPass, setNewPass] = useState("");
    const [confirmPass, setConfirmPass] = useState("");
	const [badCredentials, setBadCredentials] = useState(false);
    const [enableConfirm, setEnableConfirm] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);

    useEffect(() => {
        context.setAppHeaderNone(true);
        context.setAppSidebarNone(true);
        context.setAppContentClass("p-0");

        return function cleanUp() {
            context.setAppHeaderNone(false);
            context.setAppSidebarNone(false);
            context.setAppContentClass("");
        };
        // eslint-disable-next-line
    });

    const eye = <FontAwesomeIcon icon={faEye} />;
    const [showPass, setShowPass] = useState(false);
    const [showConfirmPass, setShowConfirmPass] = useState(false); 
    const toggleVisibilityPass = () => setShowPass(!showPass);
	function handleSubmit(event) {
		event.preventDefault();
        if (newPass === confirmPass) {
            changePasswordFn();
        } else {
            setErrorMessage("As senhas não coincidem.");
        }
	}

    const { mutateAsync: changePasswordFn } = useMutation({
        mutationKey: ['changePassword'],
        mutationFn: async () => {
            await POST("user/changePassword", {
                password: newPass,
            });
        },
        onSuccess: () => {
            setRedirect(true)
        },
        onError: (error) => {
            console.log(error)
            setBadCredentials(true);
        }
    })

	if (redirect) {
		return <Navigate to="/proposals" />;
	}

	return (
		<div className="login">
			<div className="login-content">
				<form onSubmit={handleSubmit}>
					<h1 className="text-center">Alterar Senha</h1>
					<div className="mb-3">
							<label className="form-label">
								Nova Senha 
                                <span className="text-danger">*</span>
							</label>						
                        <div className="d-flex align-items-center">
                            <input
                                type={showPass ? 'text' : 'password'}
                                className="form-control form-control-lg bg-white bg-opacity-5"
                                placeholder=""
                                onChange={(event) => setNewPass(event.target.value)}
                            />
                             <button
                                tabIndex={-1}
                                variant="outline-secondary"
                                onClick={(event) => {
                                    event.preventDefault();
                                    setShowPass(!showPass)
                                }}
                                style={{ marginLeft: '5px' }}
                                className="btn btn-outline-secondary "
                            >
                                <i>{eye}</i>
                            </button>
					    </div>
                    </div>
                    <div className="mb-3">						
							<label className="form-label">
								Confirmar Nova Senha 
                                <span className="text-danger">*</span>
							</label>
                        <div className="d-flex align-items-center">
                            <input
                               type={showConfirmPass ? 'text' : 'password'}
                                className="form-control form-control-lg bg-white bg-opacity-5"
                                placeholder=""
                                onChange={(event) => setConfirmPass(event.target.value)}
                                /> 
                            <button
                                tabIndex={-1}
                                variant="outline-secondary"
                                onClick={(event) => {
                                    event.preventDefault();
                                    setShowConfirmPass(!showConfirmPass)
                                }}
                                style={{ marginLeft: '5px' }}
                                className="btn btn-outline-secondary "
                            >
                                <i>{eye}</i>
                            </button>
                        </div>    
					</div>
                    {errorMessage && (
                        <div className="mb-3">
                            <span className="text-danger">{errorMessage}</span>
                        </div>
                    )}
                    {badCredentials && (
						<div className="mb-3">
							<span className="text-danger">
								 Senha incorreta
							</span>
						</div>
					)}
					<button
						type="submit"
						className="btn btn-outline-theme btn-lg d-block w-100 fw-500 mb-3" 
                        disabled={newPass.length === 0 || confirmPass.length === 0}
					>
						Salvar
					</button>
				</form>
			</div>
		</div>
	);
}

export default ChangePassword;

import React, { useState, useEffect, useContext } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { GET, POST } from "../services/api.ts";
import { Card } from "../components/card/card";
import { AppSettings } from "../config/app-settings";
import moment from "moment";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { useUserLogged } from "../lib/userLoggedHook.jsx";

function PageChat() {
	const context = useContext(AppSettings);

	const [msgs, setMsgs] = useState([]);
	const [msgDetails, setMsgDetails] = useState([]);
	const [isActive, setIsActive] = useState(false);
	const [id, setId] = useState(0);
	const [proposalId, setProposalId] = useState("");
	const [band, setBand] = useState("");
	const [contractor, setContractor] = useState("");
	const [proposal, setProposal] = useState(0);
	const [scrollEl, setScrollEl] = useState();
	let [searchParams, _setSearchParams] = useSearchParams();

	const { data: user, isLoading } = useUserLogged();

	const formSchema = z.object({
		msg: z.string().trim().min(1)
	})

	const { register, handleSubmit, reset } = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      msg: "",
    }
  })

	const loadChats = async () => {
		const where = user && user.type !== "admin" ? { user_id: user.id } : {};
		const res = await POST("/chat/filterheadmsg", {
			user_id: where.user_id,
		});
		const list = user ? res.data.map((row) => {
			return {
				id: row.chat_id,
				created: row.created,
				time: row.created
					? moment(row.created).format("HH:mm")
					: "--:--",
				message: row.chat_message,
				proposal_id: row.proposal_id,
				proposal_local: row.proposal_city,
				unread: row.unread,
				user_name: row.user_name,
				user_email: row.user_email,
				city: row.proposal_city,
				state: row.proposal_state,
				band: row.band_name,
				proposal: row.proposal,
			};
		}): [];	
		setMsgs(list);
	};

	useEffect(() => {
		context.setAppContentClass("p-3");
		context.setAppContentFullHeight(true);

		if(searchParams.get('proposal_id')) {
			try {
				POST("/chat/filterheadmsg", {
					proposal_id: searchParams.get('proposal_id'),
					user_id: user.id
				})
				.then((response) => {
					const chat = response.data.find((item) => item.proposal_id === +searchParams.get('proposal_id'))

					const chatConfig = {
						id: chat.chat_id, 
						proposalId: searchParams.get('proposal_id'),
						proposal: chat.proposal,
						band: chat.band_name,
						contractor: chat.user_name
					}
					getChat(
						chatConfig.id, 
						chatConfig.proposalId,
						chatConfig.proposal,
						chatConfig.band,
						chatConfig.contractor
					);
				});
			} catch (error) {
				console.log(error);
			}
		}

		return function cleanUp() {
			context.setAppContentClass("");
			context.setAppContentFullHeight(false);
			context.setAppHeaderNone(false);
		};

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (scrollEl) {
			scrollEl.scrollTop = scrollEl.scrollHeight;
		}
	});

	useEffect(() => {
		if (isLoading) {
			return;
		}
		loadChats();
	}, [isLoading]);

	function handleMessage(messageContext) {
		sendMsg(messageContext.msg)
		reset()
	}

	const sendMsg = async (msg) => {
		try {
			await POST("/chat/message/create", {
				chat_id: id,
				user_id: user.id,
				message: msg,
			});
			getChat(id, proposalId, proposal, band, contractor);
		} catch (error) {
			console.log(error);
		}
	};

	const getChat = async (id, proposal_id, proposal, band, contractor) => {
		setMsgDetails([]);
		setId(id);
		setIsActive(true);
		setProposalId(proposal_id);
		!isNaN(proposal) && setProposal(
			new Intl.NumberFormat("pt-BR", {
				maximumFractionDigits: 2,
				minimumFractionDigits: 2,
			}).format(proposal)
		);
		setBand(band);
		setContractor(contractor);
		const res = await POST("/chat/msgs", {
			proposal_id: proposal_id,
		});
		const list = res.data.map((row) => {
			return {
				id: row.id,
				created: row.created,
				date_f: moment(row.created).format("DD/MM/YYYY HH:mm"),
				date: moment(row.created).format("DD/MM/YYYY"),
				now: moment().format("DD/MM/YYYY"),
				message: row.message,
				user_name: row.name,
				type: row.type,
			};
		});
		setMsgDetails(list);
	};
	
	return (
		<Card className="h-100">
			<div className="messenger">
				<div className="messenger-sidebar">
					<div className="messenger-sidebar-header">
						<h3 className="mb-10px">Chats</h3>
						<div className="position-relative">
							<button
								type="submit"
								className="btn position-absolute top-0"
							>
								<i className="bi bi-search"></i>
							</button>
							<input
								type="text"
								className="form-control rounded-pill ps-35px"
								placeholder="Pesquisar nas conversas"
							/>
						</div>
					</div>
					<div className="messenger-sidebar-body">
						<PerfectScrollbar className="h-100">
							{msgs &&
								msgs.length > 0 &&
								msgs.map((message, ) => (
									<div
										className="messenger-item"
										key={message.proposal_id}
									>
										<a
											href="#/"
											data-toggle="messenger-content"
											className={
												isActive && message.proposal === id
													? "messenger-link active"
													: "messenger-link"
											}
											onClick={() =>
												getChat(
													message.id,
													message.proposal_id,
													message.proposal,
													message.band,
													message.user_name
												)
											}
										>
											<div className="messenger-media bg-theme text-black text-opacity-75 rounded-pill fs-24px fw-bold">
												<i className="bi bi-inbox-fill"></i>
											</div>
											<div className="messenger-info">
												<div className="messenger-name">
													#
													{String(
														message.proposal_id
													).padStart(4, "0")}
													&nbsp; [
													{message.user_name
														? message.user_name
														: "Sem mensagens"}
													]
												</div>
												<div className="messenger-text">
													{message.message}
												</div>
											</div>
											<div className="messenger-time-badge">
												<div className="messenger-time">
													{message.time}
												</div>
												<div className="messenger-badge">
													{message.unread}
												</div>
											</div>
										</a>
									</div>
								))}
						</PerfectScrollbar>
					</div>
				</div>
				<div className="messenger-content">
					{proposalId !== "" && (
						<div className="messenger-content-header">
							<div className="messenger-content-header-mobile-toggler">
								<a
									href="#/"
									data-toggle="messenger-content"
									className="me-2"
								>
									<i className="bi bi-chevron-left"></i>
								</a>
							</div>
							<div className="messenger-content-header-media">
								<div className="media bg-theme text-black text-opacity-75 rounded-pill fs-24px fw-bold">
									<i className="bi bi-inbox-fill"></i>
								</div>
							</div>
							<div className="messenger-content-header-info">
								{
									user.type === "admin" || user.type === "agent" ? (
										<div>
											Proposta #{String(proposalId).padStart(4, "0")}{" "}
											- {band} - {contractor} - R$ {proposal}
										</div>
									) : (
										<div>
											Proposta #{String(proposalId).padStart(4, "0")}{" "}
											- {band} - R$ {proposal}
										</div>
									)
								}
							</div>
						</div>
					)}
					<div className="messenger-content-body">
						<PerfectScrollbar 
							containerRef={ref => {
                setScrollEl(ref);
              }}
							className="h-100">
							{msgDetails &&
								msgDetails.length > 0 &&
								msgDetails.map((row, index) => (
									<div className="widget-chat" key={row.id}>
										{row.type === "contractor" && (
											<div className="widget-chat-item">
												<div className="widget-chat-media">
													<img
														src="/assets/img/user/avatar-phone-2.png"
														alt=""
													/>
												</div>
												<div className="widget-chat-content">
													<div className="widget-chat-name">
														{row.user_name}
													</div>
													<div className="widget-chat-message">
														{row.message}
													</div>
													<div className="widget-chat-status">
														{row.date_f}
													</div>
												</div>
											</div>
										)}
										{row.type === "admin" && (
											<div className="widget-chat-item reply">
												<div className="widget-chat-content">
													<div
														className="widget-chat-message last"
														style={{
															background:
																"#de6c49",
														}}
													>
														{row.message}
													</div>
													<div className="widget-chat-status">
														{row.date_f} [Admin -{" "}
														{row.user_name}]
													</div>
												</div>
											</div>
										)}
										{row.type === "agent" && (
											<div className="widget-chat-item reply">
												<div className="widget-chat-content">
													<div className="widget-chat-message last">
														{row.message}
													</div>
													<div className="widget-chat-status">
														{row.date_f} [Empresário -{" "}
														{row.user_name}]
													</div>
												</div>
											</div>
										)}
									</div>
								))}
						</PerfectScrollbar>
					</div>
					{proposalId !== "" && (
						<div className="messenger-content-footer">
							<form 
								onSubmit={handleSubmit(handleMessage)}
								className="input-group input-group-lg position-relative"
							>
								<button
									className="btn position-absolute start-0"
									id="trigger"
								>
									<i className="far fa-face-smile"></i>
								</button>
								<input
									type="text"
									className="form-control rounded-start ps-45px"
									id="input"
									placeholder="Escreva uma mensagem ..."
									{...register("msg")}
								/>
								<button
									className="btn btn-outline-default"
									type="submit"
								>
									<i className="fa fa-paper-plane text-muted"></i>
								</button>
							</form>
						</div>
					)}
				</div>
			</div>
		</Card>
	);
}

export default PageChat;

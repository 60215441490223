import { useQuery } from "@tanstack/react-query";
import { GET } from "../services/api";

export function useUserLogged() {
  return useQuery({
    queryKey: ['userLogged'],
    queryFn: () => GET(`/user/logged`),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
}
import React, {useState, useEffect} from 'react';
import { Card, CardHeader, CardBody, CardFooter } from './../../components/card/card.jsx';
import { Navigate, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import DateTime from 'react-datetime';
import { POST, GET } from '../../services/api.ts';
import moment from 'moment';
import qrcodepix from '../../assets/qrcodepix.png'
import ReactInputMask from 'react-input-mask';
import { useUserLogged } from '../../lib/userLoggedHook.jsx';


function Payment() {
  const location = useLocation();
	const navigate = useNavigate();
  const proposal = location.state;
	const [formData, setFormData] = useState({
		value: 'R$0,00',
		installments: 1,
		date: '',
		dueDate: ''
	});
	const [asaasInfo, setAsaasInfo] = useState({
		name: '',
		email: '',
		cpfCnpj: '',
		companyType: 'MEI',
		mobilePhone: '',
		incomeValue: 10000,
		address: '',
		addressNumber: '',
		province: '',
		postalCode: '',
	})
	const [parcelas, setParcelas] = useState([
		{
			value: 'R$0,00',
			dueDate: '',
		}
	]);
	const [newInstallments, setNewInstallments] = useState();
	const [messageError, setMessageError] = useState();
	const [asaasError, setAsaasError] = useState();
	const [payments, setPayments] = useState();
	const [asaasValidated, setAsaasValidated] = useState(true);
	const [onBoard, setOnBoard] = useState();
	const [pendingDocs, setPendingDocs] = useState();
	const [qrCode, setQrCode] = useState();
	const [loading, setLoading] = useState(false);
	const [customerStatus, setCustomerStatus] = useState(null);
	const [payerInfo, setPayerInfo] = useState({
		payerDocument: '',
		payerName: '',
	});

	const { data: user } = useUserLogged();

	useEffect(() => {
		async function getPayments() {
			if (proposal.status === "Aguardando Pagamento" && user.type === "contractor") {
				const data = await GET(`/proposal/getPayment/${proposal.id}`);
				console.log(data)
				setCustomerStatus(data.data[0].customer_status)
				setPayments(data.data);
				if (data.data[0].qr_code) {
					setQrCode(data.data.map((payment) => (
						{
							paymentUrl: payment.qr_code,
							id: payment.id
						}
					)))
				} else {
					const res = await POST(`/asaas/createPayment/`, {
						proposal_id: proposal.id,
					});
					setQrCode(res.paymentUrls)
				}
			}
		}
		getPayments();
	}, []);

	useEffect(() => {
		getAsaasUser();
	}, [user]);

	if(!user) {
		return <Navigate to="/pages/login" />
	}

	const formatCurrency = (event, index) => {
    let value = event.target.value;

    // Remove qualquer caractere que não seja número
    value = value.replace(/\D/g, '');

    // Adiciona o "R$" e formata o valor como moeda
    value = 'R$' + (parseFloat(value) / 100).toLocaleString('pt-BR', {minimumFractionDigits: 2});

    // Atualiza o estado do input
		if(event.target.id !== "incomeValue") {
			setParcelas((prevParcelas) =>
				prevParcelas.map((parcela, i) => {
					if (i === index) {
						// Atualiza o valor da parcela selecionada
						return { ...parcela, value: value };
					}
					return parcela;
				})
			);
		} else {
			setAsaasInfo({...asaasInfo, [event.target.id]: value})
		}
  };

	const handlePayer = (event) => {
		setPayerInfo({...payerInfo, [event.target.id]: event.target.value})
	}

	const modalPayment = async (event) => {
		const payment_id = parseInt(event.target.id.match(/\d+$/)[0], 10);
		const url = qrCode.find((payment) => (payment.id === payment_id))
		window.open(url.paymentUrl, '_blank');
	}

	async function getAsaasUser() {
		if (user) {
			if (user.asaas_validated === "Waiting for On Board") {
				const onBoard = await GET(`/asaas/getOnBoard/${user.id}`);
				setOnBoard(onBoard.data[0].onboardingUrl)
			} else if (user.asaas_validated === "Pending") {
				const onBoard = await GET(`/asaas/getOnBoard/${user.id}`);
				setPendingDocs(true);
			}
			if(user) {
				setAsaasValidated(user.asaas_validated)
			}
		}
	}

	async function createCustomer() {
		if (payerInfo) {
			const res = await POST(`/asaas/createCustomer/`, {
				proposal_id: proposal.id,
				payerName: payerInfo.payerName,
				payerDocument: payerInfo.payerDocument,
			});
			if (res.success === true) {
				setCustomerStatus('Done');
			}
			console.log(res)
		}
	}

	const handleAsaas = (e) => {
		if(e.target.id === "incomeValue") {
			formatCurrency(e)
			return
		}
		setAsaasInfo({...asaasInfo, [e.target.id]: e.target.value})
	}

	async function setPayment() {
		const data = await POST("/proposal/setPayment", {
			parcelas,
			proposal_id: proposal.id,
			status: "Aguardando pagamento"
		});
		console.log(data)
		navigate('/proposals');
	};

	async function sendSubAccount() {
		const data = await POST("/asaas/sendSubAccount", {
			asaasInfo, 
			proposal_id: proposal.id,
			user_id: user.id
		});
		if (data.error) {
			setAsaasError(data.error);
			return;
		}
		setLoading(true);
		await new Promise(resolve => setTimeout(resolve, 15000));
		await getAsaasUser();
	};

	function gerarParcela() {
		setParcelas([
			...parcelas,
			{value: 'R$0,00', dueDate: ''}
		])
	}

	function removerParcela() {
		if (parcelas.length > 1) {
			setParcelas(parcelas.slice(0,-1))
		}
	}

	function generateInstallments() {
		const {date, dueDate, value, installments} = formData;
		setMessageError();

		if (dueDate - new Date() < 0) {
			setMessageError('Data Inválida.')
			return
		}
		
		const cleanValue = parseFloat(value.replace("R$", "").replaceAll(".", "").replace(",", "."));

		// if (cleanValue < 1000) {
		// 	setMessageError("Valor mínimo por evento de R$1.000,00")
		// 	return
		// }
		
    // Se o número de parcelas for maior que 1, retorna a lista de parcelas
		const valorParcela = cleanValue / installments;
		const listaParcelas = [];
		let parcelaData = new Date(dueDate.getTime());
		for (let i = 0; i < installments; i++) {
			listaParcelas.push({
				installmentDate: parcelaData.toISOString(),
				value: valorParcela,
			});
			parcelaData.setDate(parcelaData.getDate() + 30);
		}
		
		// Calcula a diferença em milissegundos entre show e última parcela
		const lastInstallment = new Date(listaParcelas[listaParcelas.length - 1].installmentDate);
		const diferenca = date - lastInstallment
		// Converte a diferença para dias
		const diferencaEmDias = diferenca / (1000 * 60 * 60 * 24);
		
		// Verifica se a diferença é menor que 14 dias
		if (diferencaEmDias < 15) {
			setMessageError('O vencimento da última parcela deve ser no mínimo 15 dias antes do evento.')
			return;
		}
		setNewInstallments(listaParcelas);
    
    // Adicionar mais verificações conforme necessário

    return true;
	}

	return (
		<div>
			<div className="d-flex align-items-center mb-3">
				<div>
					<h1 className="page-header mb-0">Detalhes de Pagamento</h1>
				</div>
			</div>
	
			<div className="row gx-4">
				<div className="col-lg-8">
					<Card className="mb-4">
						<CardHeader className="d-flex align-items-center bg-inverse bg-opacity-10 fw-400">
							Informações do Evento
						</CardHeader>
						<CardBody className="text-inverse">
              <table className="table table-borderless table-sm m-0">
								<tbody>
									<tr>
										<td className="w-150px">Data</td>
										<td className="text-end">{new Date(proposal.date_event).toLocaleDateString("pt-BR")}</td>
									</tr>
                  <tr>
										<td className="w-150px">Horário</td>
										<td className="text-end">{proposal.time_event}</td>
									</tr>
                  <tr>
										<td className="w-150px">Espectadores</td>
										<td className="text-end">{proposal.quantity}</td>
									</tr>
                  <tr>
										<td className="w-150px">Tipo de Evento</td>
										<td className="text-end">{proposal.type_event}</td>
									</tr>
                  <tr>
										<td className="w-150px">Local</td>
										<td className="text-end">{proposal.local}</td>
									</tr>
                  <tr>
										<td className="w-150px">Cidade</td>
										<td className="text-end">{proposal.city} - {proposal.state}</td>
									</tr>
								</tbody>
							</table>
            </CardBody>						
					</Card>
					{proposal.status === "Assinado" && (user.type === "agent" || user.type === "band" || user.type === "admin") && asaasValidated === "None" &&
						<Card className="mb-4">
						<CardHeader className="d-flex align-items-center bg-inverse bg-opacity-10 fw-400">
							Confirme os Dados da sua Empresa
						</CardHeader>
						{!loading ? 
							<CardBody>
								<div>Antes de realizar a sua primeira venda, precisamos validar sua identidade.
								Preencha os dados abaixo e nos envie uma foto do documento do sócio proprietário bem como uma selfie.</div>
								<br />
								<div className="row">
									<div className="col-xl-6">
										<div className="form-group mb-3">
											<label className="form-label" htmlFor="exampleFormControlInput1">Nome da Empresa</label>
											<input onChange={handleAsaas} type="text" value={asaasInfo.name} className="form-control" id="name"/>
										</div>
										<div className="form-group mb-3">
											<label className="form-label" htmlFor="exampleFormControlSelect1">Tipo de Empresa</label>
											<select onChange={handleAsaas} value={asaasInfo.companyType} className="form-select" id="companyType">
												<option value={"MEI"}>MEI</option>
												<option value={"LIMITED"}>LTDA</option>
												<option value={"INDIVIDUAL"}>INDIVIDUAL</option>
											</select>
										</div>
										<div className="mb-3">
											<label className="form-label">Telefone Celular</label>
											<ReactInputMask 
												onChange={handleAsaas}
												value={asaasInfo.mobilePhone}
												mask="(99)999999999" 
												maskChar={null} 
												className="form-control" 
												id="mobilePhone"
											/>
										</div>
										<div className="mb-3">
											<label className="form-label">Logradouro</label>
											<input onChange={handleAsaas} value={asaasInfo.address} type="text" className="form-control" id="address"/>
										</div>
										<div className="mb-3">
											<label className="form-label">Bairro</label>
											<input onChange={handleAsaas} value={asaasInfo.province} type="text" className="form-control" id="province"/>
										</div>
									</div>
									<div className="col-xl-6">
										<div className="mb-3">
											<label className="form-label">E-Mail da Empresa</label>
											<input onChange={handleAsaas} value={asaasInfo.email} type="text" className="form-control" id="email"/>
										</div>
										<div className="mb-3">
											<label className="form-label">CNPJ</label>
											<ReactInputMask 
												onChange={handleAsaas}
												value={asaasInfo.cpfCnpj}
												mask="99.999.999/9999-99" 
												maskChar={null} 
												className="form-control" 
												id="cpfCnpj"
											/>
										</div>
										{/* <div className="mb-3">
											<label className="form-label">Faturamento médio mensal</label>
											<input value={asaasInfo.incomeValue} step="0.01" min="0.01" type="text" className="form-control" id="incomeValue" placeholder="R$XXX.XXX,XX" onChange={handleAsaas}/>
										</div> */}
										<div className="mb-3">
											<label className="form-label">CEP</label>
											<ReactInputMask
												onChange={handleAsaas}
												value={asaasInfo.postalCode}
												mask="99999-999" 
												maskChar={null} 
												className="form-control" 
												id="postalCode"
											/>
										</div>
										<div className="mb-3">
											<label className="form-label">Número</label>
											<input onChange={handleAsaas} value={asaasInfo.addressNumber} type="number" className="form-control" id="addressNumber"/>
										</div>
									</div>
								</div>
								{asaasError && <span style={{color: "red", marginBottom: '20px'}}>{asaasError}<br /><br /></span>}
								<button onClick={sendSubAccount} className="btn btn-outline-theme">Enviar Dados</button>		
							</CardBody>
						:
							<CardBody>
								<span>Aguarde enquanto confirmamos seus dados, isso pode levar alguns minutos!</span>
								<br />
								<div className="spinner-border"></div>
							</CardBody>
						}
						</Card>
					}
					{proposal.status === "Assinado" && (user.type === "agent" || user.type === "band" || user.type === "admin") && asaasValidated === "Done" &&
						<Card className="mb-4">
							<CardHeader className="d-flex justify-content-between align-items-center bg-inverse bg-opacity-10 fw-400">
								<span>Definir condições de Pagamento</span>		
								<div>
									<button onClick={gerarParcela} className="btn btn-outline-theme" style={{ marginRight: '15px' }}>
										<i className="fa fa-plus-circle fa-fw me-1"></i>
										Adicionar Parcela						
									</button>
									<button onClick={removerParcela} className="btn btn-outline-theme">
										<i className="fa fa-minus-circle fa-fw me-1"></i>
										Remover Parcela						
									</button>
								</div>						
							</CardHeader>
						<CardBody>
							{/* <div className="row">
								<div className="col-xl-6">
									<div className="form-group mb-3">
										<label className="form-label" htmlFor="exampleFormControlInput1">Valor do Show</label>
										<input step="0.01" min="0.01" type="text" className="form-control" id="exampleFormControlInput1" placeholder="R$XXX.XXX,XX" value={formData.value} onChange={formatCurrency}/>
									</div>
									<div className="form-group mb-3">
										<label className="form-label" htmlFor="exampleFormControlSelect1">Quantidade de Parcelas</label>
										<select onChange={(e) => {setFormData({...formData, installments: e.target.value})}} value={formData.installments} className="form-select" id="exampleFormControlSelect1">
											<option>1</option>
											<option>2</option>
											<option>3</option>
											<option>4</option>
											<option>5</option>
											<option>6</option>
											<option>7</option>
											<option>8</option>
											<option>9</option>
											<option>10</option>
											<option>11</option>
											<option>12</option>
										</select>
									</div>
								</div>
								<div className="col-xl-6">
									<div className="mb-3">
										<label className="form-label">Confirme a data do Evento</label>
										<DateTime
											closeOnSelect={true}
											dateFormat="DD/MM/YYYY"
											timeFormat={false}
											value={formData.date}
											onChange={(e) => {
												if (typeof e !== 'string') {
													setFormData({
														...formData,
														date: e.toDate(),
													})
												} else {
													setFormData({
														...formData,
														date: e,
													})
												}
											}}
										/>
									</div>
									<div className="mb-3">
										<label className="form-label">Vencimento da primeira parcela</label>
										<DateTime
											closeOnSelect={true}
											dateFormat="DD/MM/YYYY"
											timeFormat={false}
											value={formData.dueDate}
											onChange={(e) => {
												if (typeof e !== 'string') {
													setFormData({
														...formData,
														dueDate: e.toDate(),
													})
												} else {
													setFormData({
														...formData,
														dueDate: e,
													})
												}
											}}
										/>
									</div>
								</div>
							</div> */}
							{parcelas.map((parcela, index) => {
								return (
									<div className="row">
								<div className="col-xl-6">
									<div className="form-group mb-3">
										<label className="form-label" htmlFor="exampleFormControlInput1">Valor da Parcela</label>
										<input step="0.01" min="0.01" type="text" className="form-control" id="exampleFormControlInput1" placeholder="R$XXX.XXX,XX" value={parcela.value} onChange={(e) => formatCurrency(e, index)}/>
									</div>
								</div>
								<div className="col-xl-6">
									<div className="mb-3">
										<label className="form-label">Vencimento da parcela</label>
										<DateTime
											closeOnSelect={true}
											dateFormat="DD/MM/YYYY"
											timeFormat={false}
											value={parcela.dueDate}
											onChange={(e) => {
												const newDate = typeof e !== 'string' ? e.toDate() : e;
						
												setParcelas((prevParcelas) =>
													prevParcelas.map((parcela, i) => {
														if (i === index) {
															// Atualiza apenas o campo dueDate da parcela selecionada
															return { ...parcela, dueDate: newDate };
														}
														return parcela;
													})
												);
											}}
										/>
									</div>
								</div>
							</div>
								)
							})}
							{newInstallments &&
								<table className="table">
									<thead>
										<tr>
											<th scope="col">#</th>
											<th scope="col">Data de vencimento</th>
											<th scope="col">Valor</th>
										</tr>
									</thead>
									<tbody>
										{newInstallments.map((i) => {
											// let newValue = (i.value).replace(/\D/g, '');
											let newValue = 'R$' + (parseFloat(i.value)).toLocaleString('pt-BR', {minimumFractionDigits: 2});
											return (
												<tr>
													<th scope="row">1</th>
													<td>{new Date(i.installmentDate).toLocaleDateString("pt-BR")}</td>
													<td>{newValue}</td>
												</tr>
											)
										})}
									</tbody>
								</table>
							}
							{messageError && <span style={{color: "red"}}>{messageError}<br /></span>}
							<div className='d-flex'>
								<button onClick={() => setPayment()} className="btn btn-outline-theme ms-auto">
								<i className="fa fa-check-circle fa-fw me-1"></i>
								Gerar Pagamento	
							</button>		
							</div>								
						</CardBody>
						</Card>
					}
					{proposal.status === "Aguardando Pagamento" && payments && customerStatus &&
						<Card className="mb-4">
						<CardHeader className="d-flex align-items-center bg-inverse bg-opacity-10 fw-400">
							Parcelas
						</CardHeader>
						<CardBody>
							<div className="row">
								<table className="table">
									<thead>
										<tr>
											<th scope="col">#</th>
											<th scope="col">Data de vencimento</th>
											<th scope="col">Status</th>
											<th scope="col">Valor</th>
											<th scope="col">Pagar</th>
										</tr>
									</thead>
									<tbody>
										{payments.map((i) => {
											let dueDate =  moment(i.due_date).format('DD/MM/YYYY');
											// let newValue = (i.value).replace(/\D/g, '');
											let newValue = 'R$' + (parseFloat(i.value)).toLocaleString('pt-BR', {minimumFractionDigits: 2});
											return (
												<tr>
													<th scope="row">1</th>
													<td>{dueDate}</td>
													<td>{i.status}</td>
													<td>{newValue}</td>
													<td id={`payment-${i.id}`} onClick={modalPayment} type="button" className="btn btn-outline-theme">Pagar</td>
												</tr>
											)
										})}
									</tbody>
								</table>
							</div>
						</CardBody>
						</Card>
					}
					{proposal.status === "Aguardando Pagamento" && payments && !customerStatus &&
						<Card className="mb-4">
						<CardHeader className="d-flex align-items-center bg-inverse bg-opacity-10 fw-400">
							Informações do Pagador
						</CardHeader>
						<CardBody>
							<div className="row">
								<div className="col-xl-6">
									<div className="form-group mb-3">
										<label className="form-label" htmlFor="exampleFormControlInput1">Nome do Pagador</label>
										<input type="text" className="form-control" id="payerName" onChange={handlePayer}/>
									</div>
								</div>
								<div className="col-xl-6">
								<div className="form-group mb-3">
										<label className="form-label" htmlFor="exampleFormControlInput1">CPF ou CNPJ</label>
										<ReactInputMask value={payerInfo.payerDocument} mask='99999999999999' maskChar={null} className="form-control" id="payerDocument" onChange={handlePayer}/>
									</div>
								</div>
							</div>
							<button onClick={createCustomer} className="btn btn-outline-theme">Enviar Informações do Pagador</button>
						</CardBody>
						</Card>
					}
					{onBoard &&
						<Card className="mb-4">
						<CardHeader className="d-flex align-items-center bg-inverse bg-opacity-10 fw-400">
							Envio de Documento
						</CardHeader>
						<CardBody>
							<div className="row">
								<span>
									Para validar sua identidade, precisamos de uma selfie e uma foto do seu documento.
									<br />
									Se já enviou seus documentos, em até 72 horas poderemos prosseguir com o pagamento. Volte aqui para definir o pagamento.
								</span>
								<a href={onBoard} target="_blank" rel="noreferrer">Enviar documento</a>
							</div>
						</CardBody>
						</Card>
					}
					{pendingDocs &&
						<Card className="mb-4">
						<CardHeader className="d-flex align-items-center bg-inverse bg-opacity-10 fw-400">
							Envio de Documento
						</CardHeader>
						<CardBody>
							<div className="row">
								<span>
									Recebemos seus documentos e estamos validando as suas informações. Para sua segurança e para evitarmos fraudes, este processo pode levar até 72 horas. Você vai receber um e-mail e WhatsApp confirmando a sua transação.
								</span>
							</div>
						</CardBody>
						</Card>
					}
					{newInstallments && 
						<button onClick={setPayment} className="btn btn-outline-theme">Enviar cobrança</button>					
					}

				</div>
				<div className="col-lg-4">
					<Card className="mb-4">
						<CardHeader className="d-flex align-items-center bg-inverse bg-opacity-10 fw-400">
							Sobre o Evento
						</CardHeader>
						<CardBody>
							{proposal.event}
						</CardBody>
					</Card>
					<Card className="mb-4">
						<CardHeader className="d-flex align-items-center bg-inverse bg-opacity-10 fw-400">
							Contratante
						</CardHeader>
						<CardBody className="fw-bold">
							<div className="d-flex align-items-center">
								{/* <a href="#/" className="d-block"><img src="/assets/img/user/user-1.jpg" alt="" width="45" className="rounded-pill" /></a> */}
								<div className="flex-1 ps-3">
									<a className="d-block text-decoration-none">{proposal.user[0].name}</a>
									{proposal.user[0].email}
								</div>
							</div>
						</CardBody>
					</Card>
				</div>
			</div>

			<div className="modal fade" id="modalLg">
				<div className="modal-dialog modal-lg">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Pagar</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<div className="modal-body">
							Escaneie o QR Code com o aplicativo do seu banco ou copie o código para pagar pelo PIX.
							<img src={qrcodepix}></img>
							<span></span>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Payment;
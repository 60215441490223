import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader } from "../../components/card/card";
import { Modal, Button, Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Rating } from 'react-simple-star-rating';
import { Form } from "../../components/composition/Form.jsx";
import { useUserLogged } from "../../lib/userLoggedHook.jsx";
import { POST } from "../../services/api.ts";

const Feedback = () => {
  const { handleSubmit } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onBlur',
  });

  const { data: user } = useUserLogged();
  const [rating, setRating] = useState();
  const [avaliou, setAvaliou] = useState(false);
  const [comentario, setComentario] = useState('');
  const [dialogConfirmar, setDialogConfirmar] = useState(false);
  const tooltipArray = ['Ruim', 'Regular', 'Bom', 'Muito bom!', 'Ótimo'];

  const avaliar = async () => {
    try {
        const nota = tooltipArray[rating - 1];
        const tipoUsuario = user.type;
        const nome = user.name;
        const data = new Date()
        POST('/feedback/enviarFeedback', {
            comentario,
            nota,
            tipoUsuario,
            nome,
            data,
    });
      setAvaliou(true);
      closeDialog();
    } catch (error) {
    }
  };

  const closeDialog = () => {
    setDialogConfirmar(false);
  };

  const onSubmit = async () => {
    console.log('Submit called'); 
    setDialogConfirmar(true);            
  };

  useEffect(() => {
    const stars = document.querySelectorAll('.rating-container svg');
    stars.forEach((star) => {
      star.style.display = 'inline-block';
      star.style.verticalAlign = 'middle';
    });
  }, []);

  return (
    <>
    <Modal show={dialogConfirmar} onHide={closeDialog} centered>
      <Modal.Header closeButton>
        <Modal.Title>Confirma a avaliação?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <b>Comentário: </b>
            {comentario}
          </Col>
        </Row>
        <Row>
          <Col>
            <b>Nota: </b>
            {tooltipArray[rating - 1]}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeDialog}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={avaliar}>
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal>

    <Form.Root formSubmitFn={handleSubmit(onSubmit)}>
      <div>
      {!avaliou && (
        <Row style={{ display: 'inline-block', width: '60%'}}>
          <Col>
            <Card>
              <CardBody>             
                  <>
                    <Form.Group>
                      <Form.GroupField labelName={`Olá ${user?.name || ''}`} isRequired={false}>
                        <p>Por favor, avalie a plataforma</p>
                      </Form.GroupField>

                      <Form.GroupField labelName="Nota">
                      <div className="rating-container" style={{ display: 'flex', flexDirection: 'row' }}>
                            <Rating                           
                            onClick={(e) => setRating(e)}
                            initialValue={rating}
                            size={60}
                            transition
                            showTooltip
                            tooltipDefaultText= 'Sua nota'
                            tooltipArray={['Ruim', 'Regular', 'Bom', 'Muito bom!', 'Ótimo']}     
                            style={{
                              display: 'inline-flex', // Força os ícones a ficarem em linha
                              verticalAlign: 'middle' // Alinha verticalmente
                            }}                  
                            />
                        </div>
                      </Form.GroupField>

                      <Form.GroupField labelName="Envie seu comentário" isRequired={true}>
                        <textarea
                          rows={5}
                          id="comentario"
                          name="comentario"
                          className="form-control"
                          onChange={(e) => setComentario(e.target.value)}
                        />
                      </Form.GroupField>
                    </Form.Group>

                    <Button type="submit" variant="success" style={{ float: 'right' }}
                    disabled={!rating || !comentario}>
                      Avaliar
                    </Button>
                  </>
              </CardBody>
            </Card>
          </Col>
        </Row> 
        )}
       {avaliou && ( 
          <Row className="justify-content-center align-items-center" style={{ height: '100vh' }}>
            <Col md={6}>
              <Card className="text-center shadow-lg p-3 mb-5 rounded">
                <CardBody>
                    <i className="fa fa-heart text-danger" style={{ fontSize: '2rem' }}></i>
                    <h2>Agradecemos seu feedback!</h2>
                    <p>Sua opinião é muito importante para nós.</p>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </div>
    </Form.Root>
    </>
  );
};
export default Feedback;

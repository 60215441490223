import { GET } from "../services/api.ts";
import { useQuery } from '@tanstack/react-query';

async function fetchAnalytics(startDate, endDate) {
  const response = await GET(`/dashboard?startDate=${startDate}&endDate=${endDate}`);
  return response;
}

export const useAnalytics = (startDate, endDate) => {
  return useQuery({
    queryKey: ['analytics', { startDate, endDate }],
    queryFn: () => fetchAnalytics(startDate, endDate),
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    staleTime: 0,
    cacheTime: 1000,
  });
};

import axios from "axios";

const baseURL =
	process.env.REACT_APP_DEBUG === "1"
		? "http://localhost:7005"
		: "https://api.iauu.com.br";

const api = axios.create({
	baseURL: process.env.REACT_APP_DEBUG === "1"
	? "http://localhost:7005"
	: "https://api.iauu.com.br",
	headers: {
		"content-type": "application/json",
	},
	withCredentials: true,
});

// api.defaults.headers.Authorization = `Bearer ${
// 	JSON.parse(loginData).access_token
// }`;

// api.interceptors.response.use(
// 	function (config) {
// 		const user = JSON.parse(localStorage.getItem("loginInfo_access"));
// 		if (user) {
// 			config.headers.Authorization = `Bearer ${user.access_token}`;
// 		}
// 		return config;
// 	},
// 	async function (error) {
// 		if(error.response.status === 401) {
// 			const currentUrl = window.location.href.split('/');
// 			if(currentUrl.length > 5) {
// 				window.location.assign(`${currentUrl[0]}/pages/login`);
// 			}
//     }
// 	}
// );
const getImageUrl = (userId, imageType) => {
	return `${baseURL}/files/id/user-${userId}-id.${imageType}`;
  };

console.log("baseURL", baseURL);
console.log("DEBUG", process.env.REACT_APP_DEBUG);

export default api;

const GET = (url: string | void) =>
	api
		.get(url || "")
		.then((res) => res.data)
		.then((res) => {
			return res;
		})
		.catch((e) => {
			return null;
		});

const POST = (url: string | void, params: object | Array<any | void> | void) =>
	api
		.post(url || "", params)
		.then((res) => res.data)
		.then((res) => {
			return res;
		})
		.catch((e) => {
			throw e.response.data;
		});

const PUT = (url: string | void, params: object | Array<any> | void) =>
	api
		.put(url || "", params)
		.then((res) => res.data)
		.then((res) => {
			return res;
		})
		.catch((e) => {
			throw e.response.data;
		});

const DELETE = (url: string | void) =>
	api
		.delete(url || "")
		.then((res) => res.data)
		.then((res) => {
			return res;
		})
		.catch((e) => {
			throw e.response.data;
		});

export { GET, POST, PUT, DELETE, getImageUrl };

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FiLogIn } from "react-icons/fi";
import { GET } from "../../services/api";

function CustomHeader() {
	const [user, setUser] = useState(null);

	useEffect(() => {
		async function getUser() {
			const res = await GET(`/user/logged`);
			setUser(res);
		}
		getUser();
	},[]);

	return (
		<div id="header" className="app-header">
			<div className="brand">
				<Link to="/" className="brand-logo">
					<span className="brand-img">
						<span className="brand-img-text text-theme">I</span>
					</span>
					<span className="brand-text">Painel IAUÜ</span>
				</Link>
			</div>

			<div className="menu">
				<div className="menu-item dropdown dropdown-mobile-full">
					<Link
						to="/pages/login"
						className="btn-filter"
					>
						LOGIN{" "}
						<FiLogIn />
					</Link>
				</div>
			</div>
		</div>
	);
}

export default CustomHeader;

import { Fragment, useContext, useEffect, useState } from "react";
import { AppSettings } from "../../config/app-settings";
import { Card, CardBody, CardFooter, CardHeader } from "../../components/card/card";
import { FiFilter, FiMusic, FiRefreshCw, FiX } from "react-icons/fi"
import { GET, POST, getImageUrl } from "../../services/api.ts";
import { useProposalHookForm } from "../form/hook-form/proposal.js";
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { bazilStates } from "../../utils/brazilStates.js";
import CustomHeader from "../../components/header/CustromHeader.jsx"
import { Form } from "../../components/composition/Form.jsx";
import { useUserLogged } from "../../lib/userLoggedHook.jsx";

function Vitrine() {
  const context = useContext(AppSettings);
	const [filterSelected, setFilterSelected] = useState("Todos")
	const [filters, setFilters] = useState([])
	const [bands, setBands] = useState([])
	const [emailError, setEmailError] = useState(false);
	const [bandSelected, setBandSelected] = useState({})
	const [priceInterval, setPriceInterval] = useState([1000, 5000000]);
	const [hiddenFilters, setHiddenFilters] = useState(true)
	const [filterByName, setFilterByName] = useState("")
	const navigate = useNavigate()
	let bandsUrl = "/bands"
	

	const bandsIterator = filterByName.length === 0 && !hiddenFilters ? bands : bands.filter((band) => band.name.toLocaleLowerCase().startsWith(filterByName.toLocaleLowerCase()))
	const { data: user } = useUserLogged();
	const { register, handleSubmit, errors } = useProposalHookForm(user);

	context.setAppHeaderNone(!user);
	context.setAppSidebarNone(!user);
	context.setAppContentClass("p-0");
	
	useEffect(() => {
		GET("/bands/styles")
			.then((styles) => setFilters(styles))

		return function cleanUp() {
			context.setAppHeaderNone(false);
			context.setAppSidebarNone(false);
			context.setAppContentClass("");
		};
	},[]);

	useEffect(() => {
		if (filterSelected !== "Todos") {
			bandsUrl += "?" + new URLSearchParams({ style: filterSelected })
		}

		GET(bandsUrl)
			.then((bands) => setBands(bands))
			console.log(bands);

	}, [filterSelected])

	function handleClick(band) {
		setBandSelected(band)
	}

	function setTimeEvent(dateParam, time) {
		const date = new Date(dateParam)
		const hour =  Number(time.substring(0,2))
		const minutes = Number(time.substring(3))

		date.setHours(hour)
		date.setMinutes(minutes)
		return date.toISOString()
	}

	async function submitProposal(proposalSchema) {
		let userId;
		
		if(!user){
			await POST('/user', {
				name: proposalSchema.name,
				email: proposalSchema.email,
				active: 1,
				phone: null,
				admin: 0,
				type: "contractor"
			})
			userId = user.id;
			navigate("/pages/login");
		} else{
			userId = user.id;
		 }
	
		const body = {
			band_id: bandSelected.id,
			user_id: userId,
			proposal: bandSelected.cache,
    	product_id: null,
			status: "Realizada",
			created: new Date(Date.now()).toISOString(),
			...proposalSchema,
			time_event: setTimeEvent(proposalSchema.date_event, proposalSchema.time_event)
		}

		console.log(body)
 
		await POST("/proposal", body)
			.then(() => setBandSelected({}))
			.catch((error) => {
				console.log(error)
			})
	}

	function handleSliderChange(_event, newValue) {
    setPriceInterval(newValue);
  };

	function priceFormat(price) {
		const priceBRL = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(
			price,
		)
		return priceBRL
	}

	function onSelectFilterSlider() {
		if (filterSelected !== "Todos") {
			bandsUrl += "?" + new URLSearchParams({ style: filterSelected })
			bandsUrl += "&" + new URLSearchParams({ initialCache: priceInterval[0], endCache: priceInterval[1] })
			
			GET(bandsUrl)
			.then((bands) => setBands(bands))

			return;
		} 
		
		bandsUrl += "?" + new URLSearchParams({ initialCache: priceInterval[0], endCache: priceInterval[1] })
		GET(bandsUrl)
			.then((bands) => setBands(bands))
	}

  return (
		<Fragment>
			{ !user && <CustomHeader /> }
			<section className={!user ? "app-content mt-5" : "content mt-3"}>
				<section className="section-actions">
					<button
						className="filter-button"
						onClick={() => setHiddenFilters(!hiddenFilters)}
					>
						<FiFilter />
						Filtros
					</button>
				</section>
				<section className="app-filter">
					{
						!hiddenFilters && (
							<div className={"app-filter__section"}>
								<div className="mb-2 app-filter_section_close-filter">
									<FiX className="close" onClick={() => setHiddenFilters(!hiddenFilters)} />
								</div>
								<h4>Filtrar por Preço</h4>
								<p className="app-filter__section__price-indicator">
									<spa>{ priceFormat(priceInterval[0]) }</spa>	
									<spa>{ priceFormat(priceInterval[1]) }</spa>	
								</p>
								<Box>
									<Slider
										value={priceInterval}
										onChange={handleSliderChange}
										valueLabelDisplay="off"
										min={1000}
										max={5000000}
										step={1000}
									/>
								</Box>
								<button onClick={() => {
									setHiddenFilters(true);
									onSelectFilterSlider()
								}} className="btn btn-filter mb-3">
									<FiFilter />
									<span>Filtrar</span>
								</button>
		
								<input
									type="text"
									className="form-control form-control-lg bg-white bg-opacity-5 mb-3"
									placeholder="Banda..."
									value={filterByName}
									onChange={(event) => setFilterByName(event.target.value)}
								/>
								<Card className="mb-3 app-filter__card">
									<CardHeader>
										<h4>Estilo musical</h4>
									</CardHeader>
									<CardBody className="app-filter__card-body">
										<p className="app-filter__card_music-style">
											<button onClick={() => setFilterSelected("Todos")} className={`app-filter__card_music-style_button ${"Todos" === filterSelected && "focused"}`}>Todos</button>
											{
												filters.map((filter) => (
													<button key={filter.style} onClick={() => setFilterSelected(filter?.style)} className={`app-filter__card_music-style_button ${filter?.style === filterSelected && "focused"}`}>{ filter?.style }</button>
												))
											}
										</p>
									</CardBody>
								</Card>
							</div>
						)
					}
					
					<div>
						<div className="app-content__cards">
							{
								bandsIterator?.map((band) => (
									<Card key={band.id} className="mb-3 app-content__cards__card">
										<div className="app-content__cards__card-section-image"
											style={{
												position: "relative",
												width: "100%",
												height: "200px",
												overflow: "hidden",
											}}>
											<img className="absolute-section__app-content__card-section-image__image" 
												src={getImageUrl(band.id, band.imageType)}
												alt={band.name} 
												style={{
													position: "relative",
													width: "15rem",
													height: "100%",
													objectFit: "contain",
												}}/>
											
										</div>
										
										<CardBody>
											<h5>{ band.name }</h5>
											<p className="app-content__cards__card_music-style">
												<FiMusic />
												<span>{ band.style }</span>
											</p>
										</CardBody>

										<CardFooter>
											<button
												type="button"
												onClick={() => handleClick(band)}
												className="btn btn-outline-theme btn-lg d-block w-100 fw-500 mb-3"
											>
												Solicitar uma proposta
											</button>
										</CardFooter>
									</Card>
								))
							}
						</div>
					</div>
				</section>
				{
					bandSelected.name && (
						<section
							onClick={(event) => {
								event.target.className === "absolute-section" && setBandSelected({})
							}}
							className="absolute-section"
							style={{
								maxHeight: "100vh", 

								overflowY: "auto", 
							  }}
						>
							<div className="app-content absolute-section__app-content__card"
								onClick={(event) => {
									event.target.className === "app-content absolute-section__app-content__card" && setBandSelected({})
								}}
							>
								<section className="section_contents"
									onClick={(event) => {
										event.target.className === "section_contents" && setBandSelected({})
									}}
								>	
									<Card className="absolute-section__app-content__card-content">
										<div 
											className="absolute-section__app-content__card-content_form-body"
											style={{minHeight: "100%" }}
										>
											<img 		
												className="absolute-section__app-content__card-section-image__image" 
												src={getImageUrl(bandSelected.id, bandSelected.imageType)}
												alt={bandSelected.name} 
												style={{								
													width: "100%",
													height: "50%",
													objectFit: "contain",
												}}
											/>
											<CardBody >
												<h5>{bandSelected.name}</h5>
												<p>{bandSelected.about}</p>
											</CardBody>
											<CardFooter>
												<button
													type="button"
													onClick={() => setBandSelected({})}
													className="btn btn-lg button-close d-block w-100 fw-500 mb-3"
												>
													Cancelar envio de proposta
												</button>
											</CardFooter>
										</div>
									</Card>

								<Card className="absolute-section__app-content__card-content_form">
									<CardBody className="absolute-section__app-content__card-content_form-body"
									style={{minHeight: "100%" }}>
										<Form.Root
											formSubmitFn={handleSubmit(submitProposal)}
										>
											<Form.Group groupName="Informações do evento">
											{!user &&
											<><Form.GroupField labelName="Nome Contratante">
												<input {...register("name")}
													className="form-control form-control-lg bg-white bg-opacity-5"
													placeholder=""></input>
												{errors.name && (<span className="text-danger">{errors.name?.message}</span>)}
											</Form.GroupField>
											<Form.GroupField labelName="Email Contratante">
												<input {...register("email")}
													className="form-control form-control-lg bg-white bg-opacity-5"
													placeholder=""></input>
												{errors.email && (<span className="text-danger">{errors.email?.message}</span>)}
											 </Form.GroupField></>}

												<Form.GroupField labelName="Sobre o evento">
													<textarea {...register("event")} className="form-control form-control-lg bg-white bg-opacity-5" placeholder="Conte sobre o evento..."></textarea>
													{ errors.event && (<span className="text-danger">{errors.event?.message}</span>) }
												</Form.GroupField>
												
												<Form.GroupField labelName="Tipo de evento">
													<select {...register("type_event")} className="form-control form-control-lg bg-white bg-opacity-5">
														<option value="Bilhetado" className="option-select">Bilhetado</option>
														<option value="Privado" className="option-select">Privado</option>
														{ errors.type_event && (<span className="text-danger">{errors.type_event?.message}</span>) }
													</select>
												</Form.GroupField>
												
												<Form.GroupField labelName="Quantidade de pessoas">
													<input
														type="number"
														{...register("quantity")}
														className="form-control form-control-lg bg-white bg-opacity-5"
														placeholder="0"
													/>
													{ errors.quantity && (<span className="text-danger">{errors.quantity?.message}</span>) }
												</Form.GroupField>

													<Form.GroupField labelName="Cidade">
														<input
															{...register("city")}
															type="text"
															className="form-control form-control-lg bg-white bg-opacity-5"
															placeholder="Cidade..."
														/>
														{ errors.city && (<span className="text-danger">{errors.city?.message}</span>) }
													</Form.GroupField>

													<Form.GroupField labelName="Estado">
														<select {...register("state")} className="form-control form-control-lg bg-white bg-opacity-5">
															{
																bazilStates.map((state) => <option key={state} value={state} className="option-select">{state}</option>)
															}
														</select>
														{ errors.state && (<span className="text-danger">{errors.state?.message}</span>) }
													</Form.GroupField>

													<Form.GroupField labelName="Data principal">
														<input
															{...register("date_event")}
															type="date"
															className="form-control form-control-lg bg-white bg-opacity-5"
															placeholder=""
														/>
														{ errors.date_event && (<span className="text-danger">{errors.date_event?.message}</span>) }
													</Form.GroupField>

													<Form.GroupField labelName="Data alternativa 1" isRequired={false}>
														<input
															{...register("date1_event")}
															type="date"
															className="form-control form-control-lg bg-white bg-opacity-5"
															placeholder=""
														/>
													</Form.GroupField>

													<Form.GroupField labelName="Data alternativa 2" isRequired={false}>
														<input
															{...register("date2_event")}
															type="date"
															className="form-control form-control-lg bg-white bg-opacity-5"
															placeholder=""
														/>
													</Form.GroupField>

													<Form.GroupField labelName="Horário">
														<input
															{...register("time_event")}
															type="time"
															className="form-control form-control-lg bg-white bg-opacity-5"
															placeholder=""
														/>
														{ errors.time_event && (<span className="text-danger">{errors.time_event?.message}</span>) }
													</Form.GroupField>

													<Form.GroupField labelName="Local do evento">
														<input
															{...register("local")}
															type="text"
															className="form-control form-control-lg bg-white bg-opacity-5"
															placeholder="Local do evento"
														/>
														{ errors.local && (<span className="text-danger">{errors.local?.message}</span>) }
													</Form.GroupField>

													<Form.GroupField labelName="Observações" isRequired={false}>
														<textarea {...register("obs")} className="form-control form-control-lg bg-white bg-opacity-5" placeholder="informaçoes adicionais..."></textarea>
													</Form.GroupField>
												</Form.Group>
												{emailError && 
													<div style={{color: "#c72e2e"}}>
													Este Email já está associado a outra conta
													</div>}
												<button
													type="submit"
													className="btn btn-lg button-finaly d-block w-100 fw-500 mb-3"
												>
													Finalizar solicitação
												</button>
											</Form.Root>
										</CardBody>
									</Card>
								</section>
							</div>
						</section>
					)
				}
			</section>
		</Fragment>
  )
}

export default Vitrine
import React, { useContext, useEffect, useState } from 'react';
import { DashboardAdmin } from './../../components/dashboard/DashboardAdmin.jsx';
import { DashboardAgent } from '../../components/dashboard/DashboardAgent.jsx';
import { DashboardContractor } from '../../components/dashboard/DashboardContractor.jsx';
import { AppSettings } from '../../config/app-settings.js';
import { useUserLogged } from '../../lib/userLoggedHook.jsx';

function Analytics() {
	const context = useContext(AppSettings);
	useEffect(() => {
		context.setAppHeaderNone(false);
	});

	const { data: user } = useUserLogged();

	if (user && user.type === "admin")  {
		return <DashboardAdmin />
	}
	if (user && user.type === "agent")  {
		return <DashboardAgent />
	}
	if (user && user.type === "contractor")  {
		return <DashboardContractor />
	}
	return null
}

export default Analytics;
import React, { useMemo, useState } from 'react';
import { Card, CardBody } from '../card/card.jsx';
import { LineChartCustom } from '../chart/LineChart.js';
import { DateRangePickerFilter } from '../ui/dateRangePicker.jsx';
import { useAnalytics } from '../../lib/DashboardHook.jsx';
import { BarChartHorizontalCustom } from '../chart/BarChart.js';
import { chartColors } from '../../lib/utils.js';

export function DashboardAdmin() {
	const [date, setDate] = useState({});
  const defaultChart = {
		proposals: [],
		bands: [],
		gmv: [],
		clients: []
	}
	
	const { data: analytics = defaultChart } = useAnalytics(date.from, date.to);


	const total = useMemo(() => {
		const amount = new Intl.NumberFormat("pt-BR", {
			style: "currency",
			currency: "BRL",
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		}).format(analytics.gmv.reduce((total, item) => total + item.total, 0));

		return amount
	}, [analytics.gmv]);

	const totalClients = useMemo(() => {
		return analytics.clients.reduce((total, item) => total + item.total, 0)
	}, [analytics.gmv]);

	const totalProposals = useMemo(() => {
		return analytics.proposals.reduce((total, item) => total + +item.quantity, 0)
	}, [analytics.proposals]);

	const totalBands = useMemo(() => {
		return analytics.bands.reduce((total, item) => total + +item.quantity, 0)
	}, [analytics.bands]);

  return (
		<div className='flex flex-col gap-3'>
			<h1 className="page-header">
				Analítico <small>Estatísticas e Performance</small>
			</h1>

			<section>
				<DateRangePickerFilter handleChangeDate={(value) => setDate(value)} />
			</section>
			<div className='flex flex-col gap-3'>
				<section className='w-full flex flex-col gap-3'>
					<Card className="flex-1">
						<CardBody>
							<div className='flex justify-between'>
								<div className="flex flex-col mb-4">
									<span className='fw-bold fs-16px'>GMV</span>
									<p className=' text-gray-300'>Todo dinheiro movimentado na plataforma por mês</p>
								</div>
								<div>
								<span className='fw-bold fs-16px'>Total</span>
									<h2 className="text-theme text-lg">{total}</h2>
								</div>
							</div>
							
							<LineChartCustom currencyLabelFormatter={true} chartData={analytics.gmv || []} dataKey="month" nameKey="total" />
						</CardBody>
					</Card>

					<Card className="flex-1">
						<CardBody>
							<div className='flex justify-between'>
								<div className="flex flex-col mb-4">
									<span className='fw-bold fs-16px'>Clientes</span>
									<p className=' text-gray-300'>Clientes que já assinaram uma proposta</p>
								</div>
								<div className="flex flex-col mb-4">
									<span className='fw-bold fs-16px'>Total</span>
									<p className='text-theme text-lg text-right'>{totalClients}</p>
								</div>
							</div>
							<LineChartCustom chartData={analytics.clients || []} dataKey="month" nameKey="total" />
						</CardBody>
					</Card>
				</section>
				<Card className="flex-1">
					<CardBody>
						<div className='flex justify-between'>	
							<div className="flex flex-col">
								<span className='fw-bold fs-16px'>Propostas</span>
								<p className=' text-gray-300'>Todas as propostas por status</p>
							</div>
							<div className="flex flex-col mb-10">
								<span className='fw-bold fs-16px'>Total</span>
								<p className=' text-theme text-lg text-right'>{totalProposals}</p>
							</div>
						</div>
						<BarChartHorizontalCustom chartData={analytics.proposals.map((item, index) => ({ ...item, fill: chartColors[index] })) || []} dataKey="status" nameKey="quantity" />
					</CardBody>
				</Card>

				<Card className="chart-container__chart">
					<CardBody>
						<div className='flex justify-between'>	
							<div className="flex flex-col">
								<span className='fw-bold fs-16px'>Bandas</span>
								<p className=' text-gray-300'>Todas as bandas por estilo musical</p>
							</div>
							<div className="flex flex-col mb-10">
								<span className='fw-bold fs-16px'>Total</span>
								<p className=' text-theme text-lg text-right'>{totalBands}</p>
							</div>
						</div>
						<BarChartHorizontalCustom chartData={analytics.bands.map((item, index) => ({ ...item, fill: chartColors[index] })) || []} dataKey="styleBand" nameKey="quantity" />
					</CardBody>
				</Card>
			</div>
		</div>
	)
}
import { useState } from "react"
import { addDays, format } from "date-fns"
import { Calendar as CalendarIcon, Filter } from "lucide-react"
import { useQueryClient } from '@tanstack/react-query';

import { cn } from "../../lib/utils"
import { Button } from "./button"
import { Calendar } from "./calendar"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "./popover"

export function DateRangePickerFilter({
  className,
  handleChangeDate,
}) {
  const [date, setDate] = useState({})

  const queryClient = useQueryClient();

  function submitDateRange(date) {
    if (date.from) {
      const startDateTransformIso = date.from ? new Date(date.from).toISOString().replace(/T.*$/, 'T00:00:00.000Z') : null;
      const endDateTransformIso = date.to 
        ? new Date(date.to).toISOString().replace(/T.*$/, 'T23:59:59.999Z') 
        : new Date(date.from).toISOString().replace(/T.*$/, 'T23:59:59.999Z');

      handleChangeDate({ from: startDateTransformIso, to: endDateTransformIso });
      queryClient.invalidateQueries(['analytics']);
    }
  }

  return (
    <div className="flex gap-2">
      <div className={cn("grid gap-2", className)}>
        <Popover>
          <PopoverTrigger asChild>
            <Button
              id="date"
              variant={"outline"}
              className={cn(
                "w-[300px] justify-start text-left font-normal text-orange-500",
              )}
            >
              <CalendarIcon className="mr-2 h-4 w-4" />
              {date?.from ? (
                date.to ? (
                  <>
                    {format(date.from, "LLL dd, y")} -{" "}
                    {format(date.to, "LLL dd, y")}
                  </>
                ) : (
                  format(date.from, "LLL dd, y")
                )
              ) : (
                <span>Selecione um período</span>
              )}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0" align="start">
            <Calendar
              initialFocus
              mode="range"
              defaultMonth={date?.from}
              selected={date}
              onSelect={setDate}
              numberOfMonths={2}
            />
          </PopoverContent>
        </Popover>
      </div>

      <button 
        className="flex items-center p-2 bg-orange-500 text-gray-100 rounded-md cursor-pointer hover:brightness-125"
        onClick={() => submitDateRange(date)}
      >
        <Filter />
      </button>
    </div>
  )
}

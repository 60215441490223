import React, { useMemo, useState } from 'react';
import { Card, CardBody } from '../card/card.jsx';
import { LineChartCustom } from '../chart/LineChart.js';
import { BarChartCustom, BarChartHorizontalCustom } from '../chart/BarChart.js';
import { DateRangePickerFilter } from '../ui/dateRangePicker.jsx';
import { useAnalytics } from '../../lib/DashboardHook.jsx';
import { CircleX, X } from 'lucide-react';
import { chartColors } from '../../lib/utils.js';

export function DashboardAgent() {
  const defaultChart = {
		events: [],
		bands: [],
		clients: [],
		topFiveClients: []
	}

	const [date, setDate] = useState({});

	const { data: analytics = defaultChart } = useAnalytics(date.from, date.to);

	const totalAmount = useMemo(() => {
		const amount = new Intl.NumberFormat("pt-BR", {
			style: "currency",
			currency: "BRL",
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		}).format(analytics.bands.reduce((total, item) => total + item.quantity, 0));

		return amount
	}, [analytics.bands]);

	const totalEvents = useMemo(() => {
		return new Intl.NumberFormat("pt-BR", {
			style: "currency",
			currency: "BRL",
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		}).format(analytics.topFiveClients.reduce((total, item) => total + item.quantity, 0));
	}, [analytics.topFiveClients]);

	const countEvents = useMemo(() => {
		return analytics.topFiveClients.reduce((total, item) => total + item.total, 0)
	}, [analytics.topFiveClients]);

  return (
		<div className='flex flex-col gap-3'>
			<h1 className="page-header">
				Analítico <small>Estatísticas e Performance</small>
			</h1>

			<section>
				<DateRangePickerFilter handleChangeDate={(value) => setDate(value)} />
			</section>
			<div className='chart-container'>
				<section className='w-full flex flex-col gap-3'>
          <Card>
            <CardBody>
							<div className='flex justify-between'>	
								<div className="flex flex-col mb-4">
									<span className='fw-bold fs-16px'>Bandas</span>
									<p className=' text-gray-300'>Faturamento das bandas</p>
								</div>
								<div className="flex flex-col mb-10">
									<span className='fw-bold fs-16px'>Total</span>
									<p className=' text-theme text-lg text-right'>{totalAmount}</p>
								</div>
							</div>
              {
                analytics.bands.length > 0 ? (
                  <BarChartHorizontalCustom 
										currencyLabelFormatter={true}
										chartData={analytics.bands.map((item, index) => ({...item, fill: chartColors[index]})) || []}
										dataKey="bandName" nameKey="quantity" 
									/>
                ): (
                  <div className="flex flex-col justify-center items-center h-full py-4 mb-4">
                    <CircleX className='text-gray-300 w-10 h-10 ' />
                    <p className=' text-gray-300'>Nenhum faturamento encontrado</p>
                  </div>
                )
              }
            </CardBody>
          </Card>

					<Card className="flex-1">
						<CardBody>
							<div className="flex flex-col mb-4">
								<span className='fw-bold fs-16px'>Clientes</span>
								<p className=' text-gray-300'>Quantidade de clientes por mês</p>
							</div>
							<LineChartCustom chartData={analytics.clients || []} dataKey="month" nameKey="total" />
						</CardBody>
					</Card>
				</section>
				<section className='w-full flex flex-col gap-3'>
					<Card className="flex-1">
						<CardBody>
							<div>
								<div className="flex flex-col mb-4">
									<span className='fw-bold fs-16px'>Eventos</span>
									<p className=' text-gray-300'>Quantidade de eventos contratados por mês</p>
								</div>
							</div>
							<LineChartCustom chartData={analytics.events || []} dataKey="month" nameKey="total" />
						</CardBody>
					</Card>
					<Card>
            <CardBody>
              <div className="flex justify-between">
								<div>
									<span className='fw-bold fs-16px'>Top 5 clientes</span>
									<p className=' text-gray-300'>Clientes que mais contrataram eventos</p>
								</div>
               
								<section className='flex gap-3 items-center'>
									<div className="flex flex-col mb-4">
										<span className='fw-bold fs-16px'>Total em R$</span>
										<p className='text-theme text-lg text-right'>{totalEvents}</p>
									</div>
									<div className="flex flex-col mb-4">
										<span className='fw-bold fs-16px'>Total de eventos</span>
										<p className='text-theme text-lg text-right'>{countEvents}</p>
									</div>
								</section>
              </div>
              {
                analytics.topFiveClients.length > 0 ? (
                  <BarChartHorizontalCustom 
				  				chartData={
									analytics.topFiveClients.map((item, index) => ({...item, fill: chartColors[index]}))
								} 
								dataKey="userName" 
								nameKey="total" 
							/>
                ): (
                  <div className="flex flex-col justify-center items-center h-full py-4">
                    <CircleX className='text-gray-300 w-10 h-10 ' />
                    <p className=' text-gray-300'>Nenhum cliente encontrado</p>
                  </div>
                )
              }
            </CardBody>
          </Card>
				</section>
			</div>
		</div>
	)
}

import { clsx } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs) {
  return twMerge(clsx(inputs))
}

export const chartColors = [
  "rgba(249, 145, 40, 0.6)",  // Variante mais clara de #f97316
  "rgba(194, 115, 255, 0.6)", // Variante mais clara de #a855f7
  "rgba(0, 175, 240, 0.6)",   // Variante mais clara de #0ea5e9
  "rgba(45, 225, 105, 0.6)",  // Variante mais clara de #22c55e
  "rgba(180, 180, 190, 0.6)", // Variante mais clara de #a1a1aa
  "rgba(239, 85, 10, 0.6)",   // Variante mais escura de #f97316
  "rgba(130, 70, 240, 0.6)",  // Variante mais escura de #a855f7
  "rgba(10, 145, 200, 0.6)",  // Variante mais escura de #0ea5e9
  "rgba(30, 180, 85, 0.6)",   // Variante mais escura de #22c55e
  "rgba(140, 140, 150, 0.6)", // Variante mais escura de #a1a1aa
  "rgba(255, 160, 80, 0.6)",  // Laranja mais claro
  "rgba(200, 105, 250, 0.6)", // Roxo mais claro
  "rgba(5, 180, 240, 0.6)",   // Azul mais claro
  "rgba(50, 210, 110, 0.6)",  // Verde mais claro
  "rgba(190, 190, 200, 0.6)", // Cinza mais claro
  "rgba(220, 90, 25, 0.6)",   // Laranja mais escuro
  "rgba(150, 60, 220, 0.6)",  // Roxo mais escuro
  "rgba(15, 130, 190, 0.6)",  // Azul mais escuro
  "rgba(35, 175, 80, 0.6)",   // Verde mais escuro
  "rgba(130, 130, 140, 0.6)", // Cinza mais escuro
  "rgba(255, 100, 40, 0.6)",  // Laranja vibrante
  "rgba(180, 90, 230, 0.6)",  // Roxo vibrante
  "rgba(10, 155, 215, 0.6)",  // Azul vibrante
  "rgba(40, 190, 90, 0.6)",   // Verde vibrante
  "rgba(150, 150, 160, 0.6)", // Cinza vibrante
  "rgba(245, 130, 30, 0.6)",  // Laranja suave
  "rgba(170, 100, 240, 0.6)", // Roxo suave
  "rgba(0, 165, 230, 0.6)",   // Azul suave
  "rgba(30, 210, 95, 0.6)",   // Verde suave
  "rgba(175, 175, 180, 0.6)", // Cinza suave
  "rgba(250, 120, 25, 0.6)",  // Laranja quente
  "rgba(160, 80, 230, 0.6)",  // Roxo quente
  "rgba(5, 160, 225, 0.6)",   // Azul quente
  "rgba(35, 190, 85, 0.6)",   // Verde quente
  "rgba(165, 165, 170, 0.6)", // Cinza quente
  "rgba(240, 100, 15, 0.6)",  // Laranja mais intenso
  "rgba(155, 75, 225, 0.6)",  // Roxo mais intenso
  "rgba(0, 140, 215, 0.6)",   // Azul mais intenso
  "rgba(25, 170, 75, 0.6)",   // Verde mais intenso
  "rgba(135, 135, 140, 0.6)"  // Cinza mais intenso
];
import { CartesianGrid, Line, LineChart, XAxis, YAxis } from "recharts"
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "../../components/ui/chart"

const chartConfig = {
  total: {
    label: "Total",
    color: "#00b8d9",
  },
}

export function LineChartCustom({ 
    chartData, 
    dataKey, 
    nameKey, 
    currencyLabelFormatter, 
    firstTreeLabel = true,
    customLabel
}) {
  return (
    <ChartContainer className="h-200px w-full" config={chartConfig}>
      <LineChart
        data={chartData}
      >
        <CartesianGrid vertical={false} />
        <XAxis
          dataKey={dataKey}
          tickMargin={8}
          tickFormatter={(value) => firstTreeLabel ? value.slice(0, 3) : value}
          interval={0}
        />
        <YAxis
          dataKey={nameKey}
        />
        <ChartTooltip
          cursor={false}
          content={<ChartTooltipContent 
            currencyLabelFormatter={currencyLabelFormatter}
            className="text-black font-mono font-semibold"
            labelFormatter={(value, payload) => {
              return customLabel ? payload[0].payload[customLabel] : value
            }}
          />}
        />
        <Line
          dataKey={nameKey}
          type="monotone"
          stroke= "#f97316"
          strokeWidth={2}
          dot={{
            fill:  "#f97316",
          }}
          activeDot={{
            r: 6,
          }}
        />
      </LineChart>
    </ChartContainer>
  )
}

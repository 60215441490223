import { Bar, BarChart, CartesianGrid, LabelList, XAxis, YAxis } from "recharts"

import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "../ui/chart"
import { useEffect, useState, useRef } from "react";

const chartConfig = {
  quantity: {
    label: "Total",
  },
};

export function BarChartCustom({ chartData = [], dataKey, nameKey, currencyLabelFormatter = false  } = {}) {
  return (
    <ChartContainer className="min-w-80" config={chartConfig}>
      <BarChart
        accessibilityLayer
        data={chartData}
        layout="vertical"
      >
        <YAxis
          dataKey={dataKey}
          type="category"
          tickLine={false}
          tickMargin={10}
          axisLine={false}
        />
        <XAxis dataKey={nameKey} type="number" />
        <ChartTooltip
          cursor={false}
          content={
            <ChartTooltipContent 
              className="text-black"
              currencyLabelFormatter={currencyLabelFormatter}  
            />
          }
        />
        <Bar dataKey={nameKey} layout="vertical" radius={5} />
      </BarChart>
    </ChartContainer>
  )
}

const chartConfigBar = {
  quantity: {
    label: "Total",
  },
};

export function BarChartHorizontalCustom({ 
    chartData = [], 
    dataKey, 
    nameKey,
    currencyLabelFormatter = false
  } = {}) {

    const [rotateLabels, setRotateLabels] = useState(false);

  useEffect(() => {
    // Função para ajustar a rotação dos rótulos com base na largura da tela
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      // Se a tela for menor que 600px, rotaciona os rótulos
      setRotateLabels(screenWidth < 1200);
    };

    // Inicializa o valor na montagem do componente
    handleResize();

    // Adiciona o event listener para monitorar mudanças na largura da tela
    window.addEventListener('resize', handleResize);

    // Remove o listener quando o componente é desmontado
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <ChartContainer 
      className="h-200px w-full" config={chartConfigBar}>
      <BarChart data={chartData}>
        <CartesianGrid vertical={false} />
        <YAxis
          dataKey={nameKey}
        />
        <XAxis 
          dataKey={dataKey}
          interval={0}
          textAnchor={rotateLabels ? 'end' : 'middle'}
          tickLine={false}
          axisLine={false}
          verticalAnchor="start"
          angle={rotateLabels ? -40 : 0}
        />
        <ChartTooltip
          cursor={false}
          content={<ChartTooltipContent className="text-black"
            currencyLabelFormatter={currencyLabelFormatter}
            labelFormatter={(value, payload) => {
              return payload[0].payload[dataKey]
            }}
          />}
        />
        <Bar dataKey={nameKey} fill="rgba(249, 115, 22, 0.6)" />
      </BarChart>
    </ChartContainer>
  )
}

import React, { useMemo, useState } from 'react';
import { Card, CardBody } from '../card/card.jsx';
import { LineChartCustom } from '../chart/LineChart.js';
import { BarChartCustom, BarChartHorizontalCustom } from '../chart/BarChart.js';
import { DateRangePickerFilter } from '../ui/dateRangePicker.jsx';
import { useAnalytics } from '../../lib/DashboardHook.jsx';
import { CircleX, X } from 'lucide-react';
import { chartColors } from '../../lib/utils.js';

export function DashboardContractor() {
  const defaultChart = {
		events: [],
		eventCities: [],
		totalEvents: 0,
	}

	const [date, setDate] = useState({});

	const { data: analytics = defaultChart } = useAnalytics(date.from, date.to);

	const totalEvents = useMemo(() => {
		return new Intl.NumberFormat("pt-BR", {
			style: "currency",
			currency: "BRL",
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		}).format(analytics.events.reduce((total, item) => total + item.quantity, 0));
	}, [analytics.events]);

  return (
		<div className='flex flex-col gap-3'>
			<h1 className="page-header">
				Analítico <small>Estatísticas e Performance</small>
			</h1>

			<section>
				<DateRangePickerFilter handleChangeDate={(value) => setDate(value)} />
			</section>
			<div className='chart-container'>
			<section className='w-full flex flex-col gap-3'>
					<Card className="flex-1">
						<CardBody>
							<div className='flex justify-between'>
								<div className="flex flex-col mb-4">
									<span className='fw-bold fs-16px'>Eventos</span>
									<p className=' text-gray-300'>Eventos contratados por período</p>
								</div>
								<section className='flex gap-3 items-center'>
									<div className="flex flex-col mb-4">
										<span className='fw-bold fs-16px'>Total em R$</span>
										<p className='text-theme text-lg text-right'>{totalEvents}</p>
									</div>
									<div className="flex flex-col mb-4">
										<span className='fw-bold fs-16px'>Total de eventos</span>
										<p className='text-theme text-lg text-right'>{analytics.totalEvents}</p>
									</div>
								</section>
							</div>
							{
								analytics.events.length > 0 ? (
									<LineChartCustom 
										currencyLabelFormatter={true} 
										chartData={analytics.events || []} 
										dataKey="date" 
										nameKey="quantity"
										customLabel="local"
										firstTreeLabel={false}
									/>
								): (
									<div className="flex flex-col justify-center items-center h-full py-4 mb-4">
										<CircleX className='text-gray-300 w-10 h-10 ' />
										<p className=' text-gray-300'>Nenhum evento encontrado</p>
									</div>
								)
							}
						</CardBody>
					</Card>
					<Card className="flex-1">
							<div className="flex flex-col mb-4 p-3">
								<span className='fw-bold fs-16px'>Eventos</span>
								<p className=' text-gray-300'>Eventos contratados por cidade</p>
							</div>
						<CardBody>
							{
                analytics.eventCities.length > 0 ? (
                  <BarChartHorizontalCustom 
				  				chartData={
									analytics.eventCities.map((item, index) => ({...item, fill: chartColors[index]}))
								} 
								dataKey="city" 
								nameKey="quantity" 
							/>
                ): (
                  <div className="flex flex-col justify-center items-center h-full py-4">
                    <CircleX className='text-gray-300 w-10 h-10 ' />
                    <p className=' text-gray-300'>Nenhum cliente encontrado</p>
                  </div>
                )
              }
						</CardBody>
					</Card>
				</section>
			</div>
		</div>
	)
}
